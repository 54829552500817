import React from 'react';

import Title from '@/components/Title';
import Text from '@/components/Text';

const NotFoundPage = () => (
  <>
    <Title>NOT FOUND</Title>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </>
);

export default NotFoundPage;
